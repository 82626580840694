import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import Button from '../../components/ui/button'
import BobLinks from '../../components/menu/bobLinks'

import cls from './ErrorPage.module.sass'
import { createLocalizedPath } from '../../utils/localizationHelpers'
import paths from '../../paths'
import moment from 'moment-mini'

const ErrorPage = () => {
    const history = useHistory()
    const [t] = useTranslation()
    return (
        <div className={cls['oopsContainer']}>
            <img
                src={`${process.env.RAZZLE_BACKEND_URL}/static/media/bob-voyeur-exhibe-amateur.392f1925.gif`}
                width="100"
                height="100"
            />
            <div className={cls['errorMessage']}>
                <p>{t('Oops, something went wrong')}</p>
                <p>{t('Try to reload the page')}</p>
                <Button label={'Reload'} secondary onClick={() => history.go(0)} />
            </div>
            <div className={cls['menu']}>
                <div className="menuSection">
                    <div className="menuSection__title">{t('Menu')}</div>
                    <div className="menuSection__items">
                        <a className="menuSection__item" href={createLocalizedPath(paths.index.path)}>
                            {t('Home')}
                        </a>
                        <a className="menuSection__item" href={createLocalizedPath(paths.profiles.path)}>
                            {t('Profiles')}
                        </a>
                        <a
                            className="menuSection__item"
                            href={createLocalizedPath(paths.indexCertifiedFemAndCouple.path)}
                        >
                            {t('Certified females and couples')}
                        </a>
                        <a className="menuSection__item" href={createLocalizedPath('/tags')}>
                            {t('Search content')}
                        </a>
                        <a className="menuSection__item" href={createLocalizedPath(paths.indexBestPhotos.path)}>
                            {t('The best photos')}
                        </a>
                        <a className="menuSection__item" href={createLocalizedPath(paths.geo.path)}>
                            {t('Locate')}
                        </a>
                        <a
                            className="menuSection__item"
                            href={'https://www.bobvoyeur.com/forum/'}
                            target="_blank"
                            rel="noreferrer"
                        >
                            {t('Forum')}
                        </a>
                        <a
                            className="menuSection__item"
                            href={paths.archive.toPath({ year: moment().format('YYYY'), month: moment().format('MM') })}
                            target="_blank"
                            rel="noreferrer"
                        >
                            {t('Archive')}
                        </a>
                        <a className="menuSection__item" href={createLocalizedPath(paths.contact.path)}>
                            {t('Contact')}
                        </a>
                        <a
                            className="menuSection__item"
                            href={'https://www.bobvoyeur.com/arphp/'}
                            target="_blank"
                            rel="noreferrer"
                        >
                            {t('Links')}
                        </a>
                        <a className="menuSection__item" href={paths.cgu.path}>
                            {t('CGU')}
                        </a>
                    </div>
                </div>
                <BobLinks />
            </div>
        </div>
    )
}

export default ErrorPage
