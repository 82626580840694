// @ts-nocheck
import { useEffect } from 'react'
import Instanoti from '../local_modules/instanoti/instanoti'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../store/rootReducer'
import { MemberState } from '../store/member/memberTypings'
import { addNotification, addNotificationInList } from '../store/member/memberSlice'
export const useInstanoti = () => {
    const dispatch = useDispatch()
    let socket
    const WSS_URL = process.env.RAZZLE_INSTANOTI_SERVER_URL
    const wss_url = WSS_URL

    const loggedIn = useSelector<RootState, MemberState>((state) => state.member.loggedIn)

    useEffect(() => {
        if (loggedIn) {
            const socket = new Instanoti({
                server_uri: wss_url,
                on_open: (data) => {
                    console.log('---Websocket connected---')
                },
                on_close: (data) => {
                    console.log('Websocket disconnected')
                },
                on_error: (err) => {
                    console.log('Websocket error', err)
                },
                on_counter: (data) => {
                    // store.dispatch(setCurrentUserCounters(data));
                },
                on_tip: (data) => {
                    // store.dispatch(receiveInstantNotification(data));
                },
                on_follower: (data) => {
                    dispatch(
                        addNotification({
                            type: 'follower',
                            id: data.data.entity_id,
                            senderNick: data.data.meta.user.nick,
                            senderId: data.data.meta.user.id,
                            text: data.data.message,
                            url: data.data.meta.url
                        })
                    )
                    dispatch(
                        addNotificationInList({
                            type: 'follower',
                            id: data.data.id,
                            date: Number(new Date() / 1000),
                            meta: data.data.meta,
                            visibility: 'personal',
                            is_read: 0,
                            message: data.data.message
                        })
                    )
                },
                on_gift: (data) => {
                    dispatch(
                        addNotification({
                            type: 'gift',
                            id: data.data.entity_id,
                            senderNick: data.data.meta.user.nick,
                            senderId: data.data.meta.user.id,
                            text: data.data.message,
                            url: data.data.meta.url,
                            image: data.data.meta.image
                        })
                    )
                    dispatch(
                        addNotificationInList({
                            type: 'gift',
                            id: data.data.id,
                            date: Number(new Date() / 1000),
                            meta: data.data.meta,
                            visibility: 'personal',
                            is_read: 0,
                            message: data.data.message
                        })
                    )
                },
                on_message: (data) => {
                    // check if conversation with this user opened
                    let id
                    const state = store.getState()
                },
                on_membership_gift: (data) => {
                    dispatch(
                        addNotification({
                            type: 'premium',
                            duration: data.data.meta.duration,
                            id: data.data.entity_id,
                            senderNick: data.data.meta.user.nick,
                            senderId: data.data.meta.user.id,
                            text: data.data.message,
                            url: data.data.meta.url
                        })
                    )

                    dispatch(
                        addNotificationInList({
                            type: 'membership_gift',
                            id: data.data.id,
                            date: Number(new Date() / 1000),
                            meta: data.data.meta,
                            visibility: 'personal',
                            is_read: 0,
                            message: data.data.message
                        })
                    )
                },
                on_membership_gift_public: (data) => {
                    // store.dispatch(receiveInstantNotification(data))
                },
                on_guestbook: (data) => {
                    dispatch(
                        addNotification({
                            type: 'guestbook',
                            id: data.data.entity_id,
                            senderNick: data.data.meta.user.nick,
                            senderId: data.data.meta.user.id,
                            text: data.data.message,
                            url: data.data.meta.url
                        })
                    )
                    dispatch(
                        addNotificationInList({
                            type: 'guestbook',
                            id: data.data.id,
                            date: Number(new Date() / 1000),
                            meta: data.data.meta,
                            visibility: 'personal',
                            is_read: 0,
                            message: data.data.message
                        })
                    )
                },
                on_followee_contribution: (data) => {
                    dispatch(
                        addNotification({
                            type: 'newContribution',
                            id: data.data.id,
                            senderNick: data.data.meta.user.nick,
                            senderId: data.data.meta.user.id,
                            url: data.data.meta.url,
                            cover: data.data.meta.image
                        })
                    )
                    dispatch(
                        addNotificationInList({
                            type: 'followee_contribution',
                            id: data.data.id,
                            date: Number(new Date() / 1000),
                            meta: data.data.meta,
                            visibility: 'personal',
                            is_read: 0,
                            message: data.data.message
                        })
                    )
                },
                on_followed_user_is_online: (data) => {
                    // store.dispatch(receiveInstantNotification(data, true))
                },
                on_new_personal_room: (data) => {
                    // store.dispatch(receiveInstantNotification(data, true))
                },
                on_new_showroom: (data) => {
                    // store.dispatch(receiveInstantNotification(data, true))
                },
                on_user_membership_expired: (data) => {
                    // store.dispatch(receiveInstantNotification(data))
                },
                on_user_membership_almost_expired: (data) => {
                    // store.dispatch(receiveInstantNotification(data))
                },
                on_stream_record_error: (data) => {
                    // store.dispatch(receiveInstantNotification(data))
                },
                reconnect: true
            })
        } else {
            if (window && window.socket) console.log(window.socket)
        }
    }, [loggedIn])
}
