import { compile } from 'path-to-regexp'
import mapValues from 'lodash/mapValues'

type Path = {
    path: string
    toPath: (params: { [key: string]: string | number }) => string
}

type Paths = {
    index: Path
    indexPhotoFemAndCouple: Path
    indexVideoFemAndCouple: Path
    indexPhotoAndVideoMale: Path

    indexCertifiedFemAndCouple: Path
    indexBestPhotos: Path

    geo: Path

    search: Path
    searchPhoto: Path
    searchVideo: Path

    searchContributions: Path
    searchContributionsPhoto: Path
    searchContributionsVideo: Path

    recherche: Path
    tags: Path

    myProfile: Path
    myAlbum: Path
    myGuestbook: Path
    myContributions: Path
    submitContribution: Path
    notificationList: Path
    notificationSettings: Path
    myBlacklist: Path
    myFollowing: Path
    myFollowers: Path
    myWallet: Path
    myWalletHistory: Path
    myVirtualGifts: Path
    myPosts: Path
    virtualGiftCatalog: Path
    payment: Path
    premiumHistory: Path
    favoriteContributions: Path
    favoriteContributors: Path
    profile: Path

    memberMessagePmConversations: Path
    memberMessagePmRequests: Path
    memberMessagePmArchive: Path

    profiles: Path
    profileAlbum: Path
    profileContributions: Path
    profileGuestbook: Path
    profileFollowing: Path
    profileFollowers: Path
    profileGifts: Path
    profilePosts: Path
    contact: Path
    cgu: Path
    archive: Path

    contribution: Path
    photo: Path
    video: Path

    passwordRecover: Path
    userActivation: Path

    stub: Path
}

const urls = {
    index: '/',
    indexPhotoFemAndCouple: '/photos-femmes-et-couples',
    indexVideoFemAndCouple: '/videos-femmes-et-couples',
    indexPhotoAndVideoMale: '/photos-et-videos-hommes',

    indexCertifiedFemAndCouple: '/femmes-et-couples-certifies/',
    indexBestPhotos: '/les-meilleures-photos/',

    geo: '/geo/',

    recherche: '/recherche',
    tags: '/liste-de-mots-cles',

    search: '/tags/:tag?',
    searchPhoto: '/tags/photo/:tag?',
    searchVideo: '/tags/video/:tag?',

    searchContributions: '/tags/contributions/:tag?',
    searchContributionsPhoto: '/tags/contributions/photo/:tag?',
    searchContributionsVideo: '/tags/contributions/video/:tag?',

    myProfile: '/membre',
    myAlbum: '/membre/album',
    myGuestbook: '/membre/livre-d-or',
    submitContribution: '/membre/submit',
    myContributions: '/membre/contributions',
    notificationList: '/membre/notification-list',
    notificationSettings: '/membre/notification-settings',
    myBlacklist: '/membre/blacklist',
    myFollowing: '/membre/following',
    myFollowers: '/membre/followers',
    myPosts: '/membre/posts',
    myWallet: '/membre/wallet',
    myWalletHistory: '/membre/wallet/history',
    myVirtualGifts: '/membre/cadeaux-virtuels',
    virtualGiftCatalog: '/catalog/gift',
    premiumHistory: '/membre/historique-des-paiements',
    payment: '/membre/payment',
    favoriteContributions: '/membre/favorite/contributions',
    favoriteContributors: '/membre/favorite/contributors',
    profiles: '/profiles/',

    memberMessagePmConversations: '/membre/pm/conversations',
    memberMessagePmRequests: '/membre/pm/requests',
    memberMessagePmArchive: '/membre/pm/archive',

    profile: '/profil/:nick',
    profileAlbum: '/profil/:nick/album',
    profileContributions: '/profil/:nick/contributions',
    profileGuestbook: '/profil/:nick/guest_book',
    profileFollowing: '/profil/:nick/following',
    profileFollowers: '/profil/:nick/followers',
    profilePosts: '/profil/:nick/posts',
    profileGifts: '/profil/:nick/gifts',
    contact: '/contact/',
    cgu: '/cgu',
    archive: '/archive/:year?/:month?',

    contribution: '/contribution/:number/:title?',
    photo: '/photo/:material_id/:title?',
    video: '/video/:material_id/:title?',

    passwordRecover: '/passwordrecover/change/',
    userActivation: '/user/registration/activate/:token?',

    stub: '/login_required'
}

export const pureUrls = {
    search: '/tags/',
    searchContributions: '/tags/contributions/'
}

const paths: Paths = mapValues(urls, (value) => ({ path: value, toPath: compile(value) }))
export default paths
