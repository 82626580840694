import { combineReducers } from '@reduxjs/toolkit'

import contributionsReducer from './contributions/contributionsSlice'
import archiveReducer from './archive/archiveSlice'
import tagsReducer from './tags/tagsSlice'
import profilesReducer from './profiles/profilesSlice'
import profileReducer from './profile/profileSlice'
import memberReducer from './member/memberSlice'
import commonSlice from './common/commonSlice'
import geoSlice from './geo/geoSlice'
import advSlice from './adv/advSlice'
import materialsReducer from './materials/materialsSlice'
//@ts-ignore
import pm from 'pm-js/reducers/pmConversations'
import cacheReducers from './cacheSlise/cacheSlice'

const rootReducer = combineReducers({
    contributions: contributionsReducer,
    tags: tagsReducer,
    materials: materialsReducer,
    profiles: profilesReducer,
    profile: profileReducer,
    member: memberReducer,
    common: commonSlice,
    geo: geoSlice,
    adv: advSlice,
    pm: pm,
    archive: archiveReducer,
    cache: cacheReducers
})

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer
