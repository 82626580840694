import { createSlice, createAsyncThunk, PayloadAction, createAction } from '@reduxjs/toolkit'
import assign from 'lodash/assign'
import findIndex from 'lodash/findIndex'

import {
    login,
    getMember,
    logout,
    register,
    activate,
    uploadPhoto,
    editPhoto,
    deletePhoto,
    switchGuestbook,
    getBlacklistedUsers,
    addToBlacklist,
    removeFromBlacklist,
    uploadAvatar,
    updateProfile,
    getFollowees,
    getFollowers,
    addFollowedUser,
    removeFollowedUser,
    getFavoriteContributors,
    removeFavoriteContributors,
    getMemberContributions,
    getMemberFavoriteContributions,
    getPaymentHistory,
    getWalletHistory,
    getMemberGifts,
    getMemberSavedGifts,
    getUserGuestbookList,
    getMemberNotifications,
    notificationsSet,
    getUserForumPosts
} from '../../api/member'

import { getProfile } from '../../api/profiles'

import {
    MemberState,
    Login,
    FetchMemberPayload,
    Register,
    UploadPhoto,
    UploadPhotoPayload,
    EditPhotoType,
    EditPhotoPayload,
    DeletePhoto,
    DeletePayload,
    SwitchGuestbook,
    Activate,
    DeleteGuestbookMessage,
    GetBlacklistedUsers,
    AddToBlacklist,
    GetBlacklistedUsersPayload,
    AddToBlacklistPayload,
    BlacklistItem,
    RemoveFromBlacklist,
    UploadAvatar,
    UpdateProfile,
    UploadAvatarPayload,
    GetFolloweesPayload,
    GetFollowees,
    FollowUserPayload,
    FollowUser,
    FolloweeItem,
    GetFollowersPayload,
    GetFavoriteContributorsPayload,
    GetContributionsPayload,
    GetContributions,
    FetchMemberGiftsPayload,
    SingleActiveNotification,
    FetchGuestbookPayload,
    FetchNotificationsListPayload,
    SetNotificationsSetting,
    FetchUserForumPostsPayload,
    FetchUserForumPosts
} from './memberTypings'
import { deleteGuestbookMessage } from '../../api/profile'

import { GuestbookMessage, PhotoType } from '../commonTypes'
import { FetchMember, FetchProfile, FetchProfilePayload } from '../profile/profileTypings'
import {
    profileFulfilledReducer,
    pendingMemberReducer,
    rejectedMemberReducer,
    rejectRegisterReducer
} from '../commonReducers'
import { currentErrorInfo } from '../../api/axios'
import { ConversationActionMessage } from 'pm-js/src/types/pm'

const initialState: MemberState = {
    loggedIn: false,
    triedLoad: false,
    user: null,
    error: null,
    followers: [],
    followersTotal: null,
    followees: [],
    followeesTotal: null,
    contributionsList: [],
    contributionsTotal: null,
    favoriteContributors: [],
    favoriteContributorsTotal: null,
    favoriteContributions: [],
    favoriteContributionsTotal: null,
    virtualGifts: [],
    virtualGiftsTotal: null,
    savedGifts: [],
    savedGiftsTotal: null,
    paymentHistory: null,
    loading: false,
    userPhotos: [],
    userGuestbook: [],
    guestbookTotal: null,
    userBlacklist: [],
    blacklistTotal: null,
    userContributions: [],
    profileLoaded: false,
    wallet: {
        list: null,
        total_count: null,
        total_credit: null,
        total_debit: null
    },
    activeNotifications: [],
    userNotifications: [],
    userNotificationsTotal: null,
    currentPageOfNotifications: 0,
    userForumPosts: [],
    userForumPostsTotal: null,
    cookies: null
}

export const fetchMemberForumPosts = createAsyncThunk<FetchUserForumPostsPayload, FetchUserForumPosts>(
    'fetchMemberForumPosts',
    (params) => getUserForumPosts(params)
)

export const memberLogin = createAsyncThunk<FetchMemberPayload, Login>('member/login', (params) => login(params))

export const memberRegister = createAsyncThunk<FetchMemberPayload, Register>('member/register', (params) =>
    register(params)
)

export const memberActivate = createAsyncThunk<FetchMemberPayload, Activate>('member/activate', (params) =>
    activate(params)
)

export const memberFetch = createAsyncThunk<FetchMemberPayload, FetchMember>('member/fetch', (params) =>
    getMember(params)
)

export const memberProfileFetch = createAsyncThunk<FetchProfilePayload, FetchProfile>(
    'memberProfile/fetch',
    (params: FetchProfile) => getProfile(params)
)

export const memberLogout = createAsyncThunk('member/logout', () => logout())

export const photoUpload = createAsyncThunk<UploadPhotoPayload, UploadPhoto>('photo/upload', (params) =>
    uploadPhoto(params)
)

export const photoEdit = createAsyncThunk<EditPhotoPayload, EditPhotoType>('photo/edit', (params) => editPhoto(params))

export const photoDelete = createAsyncThunk<DeletePayload, DeletePhoto>('photo/delete', (params) => deletePhoto(params))

export const guestbookSwitch = createAsyncThunk<FetchMemberPayload, SwitchGuestbook>('switchGuestbook', (params) =>
    switchGuestbook(params)
)

export const guestbookMessageDelete = createAsyncThunk<DeletePayload, DeleteGuestbookMessage>(
    'deleteGuestbookMessage',
    (params) => deleteGuestbookMessage(params)
)

export const fetchBlacklistedUsers = createAsyncThunk<GetBlacklistedUsersPayload, GetBlacklistedUsers>(
    'fetchBlacklistedUsers',
    (params: GetBlacklistedUsers) => getBlacklistedUsers(params)
)

export const blockUser = createAsyncThunk<AddToBlacklistPayload, AddToBlacklist>('addToBlacklist', (params) =>
    addToBlacklist(params)
)

export const unblockUser = createAsyncThunk<DeletePayload, RemoveFromBlacklist>('removeFromBlacklist', (params) =>
    removeFromBlacklist(params)
)

export const avatarUpload = createAsyncThunk<UploadAvatarPayload, UploadAvatar>(
    'avatarUpload',
    (params: UploadAvatar) => uploadAvatar(params)
)

export const profileUpdate = createAsyncThunk<FetchMemberPayload, UpdateProfile>('profileUpdate', (params) =>
    updateProfile(params)
)

export const fetchFollowees = createAsyncThunk<GetFolloweesPayload, GetFollowees>(
    'fetchFollowees',
    (params: GetFollowees) => getFollowees(params)
)

export const fetchFollowers = createAsyncThunk<GetFollowersPayload, GetFollowees>(
    'fetchFollowers',
    (params: GetFollowees) => getFollowers(params)
)

export const fetchMemberContributions = createAsyncThunk<GetContributionsPayload, GetContributions>(
    'fetchMemberContributions',
    (params: GetContributions) => getMemberContributions(params)
)

export const fetchFavoriteContributors = createAsyncThunk<GetFavoriteContributorsPayload, GetFollowees>(
    'fetchFavoriteContributors',
    (params: GetFollowees) => getFavoriteContributors(params)
)

export const followUser = createAsyncThunk<FollowUserPayload, FollowUser>('followUser', (params) =>
    addFollowedUser(params)
)

export const unfollowUser = createAsyncThunk<DeletePayload, FollowUser>('unfollowUser', (params) =>
    removeFollowedUser(params)
)

export const deleteFavoriteContributor = createAsyncThunk<DeletePayload, FollowUser>(
    'deleteFavoriteContributor',
    (params) => removeFavoriteContributors(params)
)

export const fetchFavoriteContributionsList = createAsyncThunk(
    'fetchFavoriteContributionsLis',
    (params: { p: number }) => getMemberFavoriteContributions(params)
)

export const fetchPaymentHistory = createAsyncThunk('fetchPaymentHistory', (params: { p: number }) =>
    getPaymentHistory(params)
)

export const fetchWalletHistory = createAsyncThunk('fetchWalletHistory', (params: { p: number }) =>
    getWalletHistory(params)
)

export const fetchMemberGifts = createAsyncThunk<FetchMemberGiftsPayload, { p: number }>('fetchMemberGifts', (params) =>
    getMemberGifts(params)
)

export const fetchMemberGuestbookList = createAsyncThunk<FetchGuestbookPayload, { nick: string; p: number }>(
    'fetchMemberGuestbookList',
    (params) => getUserGuestbookList(params)
)
export const fetchSavedGifts = createAsyncThunk<FetchMemberGiftsPayload, { p: number }>('fetchSavedGifts', (params) =>
    getMemberSavedGifts(params)
)

export const fetchMemberNotifications = createAsyncThunk<FetchNotificationsListPayload, { p: number }>(
    'fetchMemberNotifications',
    (params) => getMemberNotifications(params)
)

const newMessage = createAction('PM_MESSAGE')
const saveCookies = createAction('saveCookies')

const memberSlice = createSlice({
    name: 'member',
    initialState,
    reducers: {
        photoUploadError(state) {
            state.error = 'You can load only one photo'
        },
        photoUploadErrorClean(state) {
            state.error = null
        },
        deleteErrorInfo(state) {
            state.error = null
        },
        switchHideShowGift(state, action: PayloadAction<string | number>) {
            const index = state.virtualGifts.findIndex((item) => item.id == action.payload)
            if (index >= 0)
                state.virtualGifts[index].status === 'hidden'
                    ? (state.virtualGifts[index].status = 'read')
                    : (state.virtualGifts[index].status = 'hidden')
        },
        addNotification(state, action: PayloadAction<SingleActiveNotification>) {
            state.activeNotifications.push(action.payload)
        },
        deleteNotification(state, action: PayloadAction<SingleActiveNotification>) {
            const index = findIndex(state.activeNotifications, action.payload)
            if (index >= 0) state.activeNotifications.splice(index, 1)
        },
        clearNotifications(state) {
            state.activeNotifications = []
        },
        addNotificationInList(state, action) {
            state.userNotificationsTotal = state.userNotificationsTotal + 1
            const arr = [action.payload]
            state.userNotifications = arr.concat(state.userNotifications)
        },
        setNotificationSettings(state, action) {
            notificationsSet(action.payload)
            state.user.notification_settings = action.payload
        }
    },
    extraReducers: (builder) => {
        const userFulfilledReducer = (state: MemberState, action: PayloadAction<FetchMemberPayload>) => {
            state.loading = false
            if (
                typeof window === 'undefined' &&
                state.cookies &&
                state.cookies.PHPSESSID &&
                state.cookies.PHPSESSID !== action.payload.PHPSESSID
            ) {
                console.log(
                    `COOKIE MISMATCH: expected - ${state.cookies.PHPSESSID}; returned ${action.payload.PHPSESSID}`
                )
                return
            }
            state.loggedIn = true
            state.triedLoad = true
            state.user = action.payload.user
            if (action.payload.userPhotos) state.userPhotos = action.payload.userPhotos
            if (action.payload.userGuestbook) state.userGuestbook = action.payload.userGuestbook
            // if (action.payload.userContributions) state.contributionsList = action.payload.userContributions
            if (action.payload.userForumPosts) state.userForumPosts = action.payload.userForumPosts
        }
        // @ts-ignore
        builder
            .addCase(newMessage, (state, action: ConversationActionMessage) => {
                if (action.message.sender_nick !== state.user.nick) {
                    state.activeNotifications.push({
                        type: 'message',
                        id: Number(new Date()),
                        senderNick: action.message.sender_nick,
                        senderId: action.message.sender_uid
                    })
                }
            })
            .addCase(fetchMemberNotifications.pending, pendingMemberReducer)
            .addCase(fetchMemberNotifications.fulfilled, (state, action) => {
                if (Number(action.payload.total) >= state.userNotificationsTotal)
                    state.userNotificationsTotal = Number(action.payload.total)
                if (
                    state.currentPageOfNotifications < state.userNotificationsTotal / 20 ||
                    !state.userNotificationsTotal
                ) {
                    state.currentPageOfNotifications = state.currentPageOfNotifications + 1
                    // @ts-ignore
                    state.userNotifications = state.userNotifications.concat(action.payload.list)
                }
            })
            .addCase(memberLogin.pending, pendingMemberReducer)
            .addCase(memberFetch.pending, pendingMemberReducer)
            .addCase(memberRegister.pending, pendingMemberReducer)
            .addCase(memberActivate.pending, pendingMemberReducer)
            .addCase(memberProfileFetch.pending, pendingMemberReducer)
            .addCase(fetchFavoriteContributionsList.pending, pendingMemberReducer)
            .addCase(memberLogin.fulfilled, userFulfilledReducer)
            .addCase(memberFetch.fulfilled, userFulfilledReducer)
            .addCase(memberRegister.fulfilled, userFulfilledReducer)
            .addCase(memberActivate.fulfilled, userFulfilledReducer)
            .addCase(guestbookSwitch.fulfilled, userFulfilledReducer)
            .addCase(memberProfileFetch.fulfilled, profileFulfilledReducer)
            .addCase(photoUpload.fulfilled, (state, action) => {
                state.userPhotos = action.payload.list
                state.error = undefined
            })
            .addCase(fetchMemberGifts.pending, pendingMemberReducer)
            .addCase(fetchMemberGifts.rejected, rejectedMemberReducer)
            .addCase(fetchMemberGifts.fulfilled, (state, action) => {
                state.virtualGifts = action.payload.list
                state.virtualGiftsTotal = action.payload.total_count
            })
            .addCase(fetchMemberGuestbookList.pending, pendingMemberReducer)
            .addCase(fetchMemberGuestbookList.rejected, rejectedMemberReducer)
            .addCase(fetchMemberGuestbookList.fulfilled, (state, action) => {
                state.userGuestbook = action.payload.list
                state.guestbookTotal = action.payload.total
            })
            .addCase(fetchSavedGifts.pending, pendingMemberReducer)
            .addCase(fetchSavedGifts.rejected, rejectedMemberReducer)
            .addCase(fetchSavedGifts.fulfilled, (state, action) => {
                state.savedGifts = action.payload.list
                state.savedGiftsTotal = action.payload.total_count
            })
            .addCase(fetchFavoriteContributionsList.fulfilled, (state, action) => {
                state.favoriteContributions = action.payload.list
                state.favoriteContributionsTotal = action.payload.total_count
            })
            .addCase(fetchWalletHistory.pending, pendingMemberReducer)
            .addCase(fetchWalletHistory.fulfilled, (state, action) => {
                state.wallet.list = action.payload.list
                state.wallet.total_count = Number(action.payload.total_count)
                state.wallet.total_credit = Number(action.payload.total_credit)
                state.wallet.total_debit = Number(action.payload.total_debit)
            })
            .addCase(fetchWalletHistory.rejected, (state) => {
                state.loading = false
            })
            .addCase(photoUpload.rejected, (state) => {
                state.error = currentErrorInfo.message
            })
            .addCase(photoEdit.fulfilled, (state, action) => {
                const photoIndex = state.userPhotos.findIndex(
                    (photo: PhotoType) => photo.id === action.payload.photo.id
                )
                state.userPhotos[photoIndex] = action.payload.photo
            })
            .addCase(photoDelete.fulfilled, (state, action) => {
                state.userPhotos = state.userPhotos.filter((photo: PhotoType) => photo.id !== action.meta.arg.id)
            })
            .addCase(guestbookMessageDelete.fulfilled, (state, action) => {
                state.userGuestbook = state.userGuestbook.filter(
                    (item: GuestbookMessage) => item.id !== action.meta.arg.id
                )
            })
            .addCase(fetchBlacklistedUsers.fulfilled, (state, action) => {
                state.userBlacklist = action.payload.list
                state.blacklistTotal = Number(action.payload.total)
            })
            .addCase(blockUser.fulfilled, (state, action) => {
                state.userBlacklist = [...state.userBlacklist, action.payload.blacklistItem]
            })
            .addCase(unblockUser.fulfilled, (state, action) => {
                state.userBlacklist = state.userBlacklist.filter(
                    (item: BlacklistItem) => Number(item.blacklisted_user_id) !== action.meta.arg.blacklisted_user_id
                )
            })

            .addCase(profileUpdate.fulfilled, (state, action) => {
                state.user = assign(state.user, action.payload.user)
            })

            .addCase(avatarUpload.fulfilled, (state, action) => {
                // @ts-ignore
                state.user.avatar_url = action.payload.avatar_url
            })

            .addCase(fetchFollowers.fulfilled, (state, action) => {
                state.followers = action.payload.list
                state.followersTotal = Number(action.payload.total)
            })
            .addCase(fetchFollowees.fulfilled, (state, action) => {
                state.followees = action.payload.list
                state.followeesTotal = Number(action.payload.total)
            })
            .addCase(fetchMemberContributions.fulfilled, (state, action) => {
                state.contributionsList = action.payload.list
                state.contributionsTotal = Number(action.payload.total_count)
            })
            .addCase(followUser.fulfilled, (state, action) => {
                state.followees = [...state.followees, action.payload.followee]
            })
            .addCase(unfollowUser.fulfilled, (state, action) => {
                state.followees = state.followees.filter(
                    (item: FolloweeItem) => Number(item.followee_id) !== action.meta.arg.id
                )
            })

            .addCase(fetchFavoriteContributors.fulfilled, (state, action) => {
                state.favoriteContributors = action.payload.list
                state.favoriteContributorsTotal = Number(action.payload.total)
            })

            .addCase(memberLogin.rejected, rejectedMemberReducer)
            .addCase(memberFetch.rejected, (state) => {
                state.loading = false
                state.triedLoad = true
            })
            .addCase(memberRegister.rejected, rejectRegisterReducer)
            .addCase(memberActivate.rejected, rejectedMemberReducer)
            .addCase(memberProfileFetch.rejected, rejectedMemberReducer)

            .addCase(memberLogout.fulfilled, () => {
                return initialState
            })
            .addCase(fetchPaymentHistory.pending, (state) => {
                state.loading = true
            })
            .addCase(fetchPaymentHistory.rejected, (state) => {
                state.loading = false
            })
            .addCase(fetchPaymentHistory.fulfilled, (state, action) => {
                state.loading = false
                state.paymentHistory = action.payload
            })
            .addCase(fetchMemberForumPosts.fulfilled, (state, action) => {
                state.userForumPosts = action.payload.list
                state.userForumPostsTotal = Number(action.payload.total_count)
            })
            .addCase(saveCookies, (state, action: any) => {
                state.cookies = action.value
            })
    }
})

export default memberSlice.reducer
export const {
    photoUploadError,
    photoUploadErrorClean,
    deleteErrorInfo,
    switchHideShowGift,
    addNotification,
    deleteNotification,
    clearNotifications,
    addNotificationInList,
    setNotificationSettings
} = memberSlice.actions
